<template>
  <div class="my-2">
    <template v-if="selectedFlag">
      <div class="cursor-pointer">
        <div class="available-action flex px-4 py-2" @click="downloadShipments()">
          <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Download') }} {{ `(${selected.length})` }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select the order you want to download!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none unavailable-action flex px-4 py-2">
            <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm ml-2">{{ $t('Download') }}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
export default {
  props: ['selected'],
  data () {
    return {
      headerTitle: ['Transfer Frequency', 'Created', 'Statement Start Date', 'Statement End Date', 'Payout Method',  'Merchant ID', 'Merchant QB ID', 'Merchant Name', 'Statement Number', 'Creditor Name', 'Creditor Account Number', 'Creditor Bank', 'Cash Collections Opening Balance', 'Payouts in Period', 'Total Service Fees Paid', 'Refunds Completed', 'Cash Collections Closing Balance', 'Wallet Opening Balance', 'Wallet Closing Balance', 'Amounts on Hold', 'Total Service Fees Due', 'Payout'],
      headerVal: ['wallet_transfer_recieved', 'created', 'period_from', 'period_to', 'payout_method', 'merchant_id', 'merchant_id_qb', 'merchant_name', 'number', 'creditor_name', 'creditor_number', 'creditor_bank', 'opening_balance', 'payouts', 'cash_service_fees', 'cash_refunds',  'closing_balance', 'wallet_opening_balance', 'wallet_closing_balance', 'amounts_on_hold', 'net_service_fees_due', 'payout'],
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    downloadShipments () {
      let list = []
      import('@/vendor/Export2Excel').then(excel => {
        list = this.selected.filter(element => element.v2 && element.v2.payout > 0)
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'Statements',
          autoWidth: true
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        switch (j) {
        case 'merchant_id_qb':
          return v.quickbooks_id
        case 'merchant_id':
          return v.merchant.id
        case 'created':
          return `${v.created.split('T')[0]  } - ${  v.created.split('T')[1].split('.')[0]}`
        case 'merchant_name':
          return v.merchant.name
        case 'payout_method':
          return v.payout_method ? v.payout_method.bank ? 'Bank' : v.payout_method.wallet ? 'Wallet' : '' : 'N/A'
        case 'creditor_number':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.account_number : v.payout_method && v.payout_method.wallet ? v.payout_method.wallet.number : 'N/A'
        case 'creditor_name':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.creditor_name : 'N/A'
        case 'creditor_bank':
          return v.payout_method && v.payout_method.bank ? v.payout_method.bank.name : 'N/A'
        case 'opening_balance':
          return v.v2.opening_balance_cod
        case 'closing_balance':
          return v.v2.cash_closing_balance
        case 'payouts':
          return v.v2.cash_payouts
        case 'wallet_opening_balance':
          return v.v2.opening_balance_wallet
        case 'wallet_closing_balance':
          return v.v2.wallet_closing_balance
        case 'cash_service_fees':
          return v.v2.cash_service_fees
        case 'amounts_on_hold':
          return v.v2.on_hold
        case 'cash_refunds':
          return v.v2.cash_refunds + v.v2.wallet_refunds
        case 'wallet_transfer_recieved':
          return v.merchant.billing_days.length
        case 'period_from':
          return `${v.v2.period_from.split('T')[0]  } - ${v.v2.period_from.split('T')[1].split('.')[0]}`
        case 'period_to':
          return `${v.v2.period_to.split('T')[0]  } - ${v.v2.period_to.split('T')[1].split('.')[0]}`
        case 'payout':
          return v.v2 ? v.v2.payout : v.net_cod_due
        }
        return v[j] ? v[j] : 'N/A'
      }))
    }
  }
}
</script>